<template>
  <div>
    <div class="buttonContainer">
      <button
          id="showMantraButton"
          @click="fetchAllData"
          :disabled="disableMantraButton"
          type="button"
          class="button"
      >
        Zeige Mantra
      </button>
    </div>
    <div class="buttonContainer loadingContainer" v-if="displayLoadingIcon">
      <div class="loadingObject spinner-border"></div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import { RenderEngineUtils, sendClickEvent } from "@/renderEngine/RenderEngine";
import * as Parameters from "@/Parameters";
import { Sleep } from "@/renderEngine/Utils";

export default {
  setup() {
    const disableMantraButton = ref(false);
    const displayLoadingIcon = ref(false);
    const theme = RenderEngineUtils.Theme;

    const fetchAllData = async () => {
      disableMantraButton.value = true;
      displayLoadingIcon.value = true;

      let response;
      try {
        response = await axios.get(
            "/projects/event_it/mantramizer/sources/lib/php/getMantras.php?mprefix=event_it&pprefix=mantramizer&unit=" +
            Parameters.unit,
            {
              action: "fetchAll",
            }
        );
        response.data.mantra_text = response.data.mantra_text.replaceAll(
            "&#39;",
            "'"
        );
        response.data.mantra_text = response.data.mantra_text.replaceAll(
            "&#34;",
            '"'
        );
      } catch (error) {
        console.error(error);
        response = {
          data: {
            mantra_text: "Something went wrong with the HTTP-request",
            mantra_type: "Sorry :(",
          },
        };
      }

      displayLoadingIcon.value = false;

      await sendClickEvent(response.data);
      await Sleep(1000); // sleep for a second
      disableMantraButton.value = false;
    };

    return {
      disableMantraButton,
      displayLoadingIcon,
      theme,
      fetchAllData,
    };
  },
};
</script>

<style>
.buttonContainer {
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: center;
}
.button {
  margin: auto;
  min-width: 250px;
  max-width: 500px;
  font-size: x-large;
  border: 3px solid beige;
  background: #0a0a0a;
  color: beige;
  padding: 10px 35px;
  border-radius: 5px;
  transition: transform 0.3s ease;
  transform: scale(1);
}

.button:hover {
  border: 3px solid darkslategray;
}
.button:disabled {
  transform: scale(0, 0.4);
}

.loadingContainer {
  bottom: 40px;
  z-index: 10;
}

.loadingObject {
  color: v-bind(theme["MainColor"]);
}
</style>