import { createApp } from 'vue';
import App from './App.vue';
import { createRenderer, setScene, startRendering, appendRendererTo, createScene } from "./renderEngine/RenderEngine";
import * as Parameters from "@/Parameters";
import { SceneList } from "@/renderEngine/SceneList";

Parameters.handleGetParameters();

let backgroundElem = document.createElement('div');
backgroundElem.id = "background";
document.body.appendChild(backgroundElem);

let loading = document.createElement('div');
loading.className = "loading spinner-border";
document.body.appendChild(loading);

initRenderer();

function initRenderer() {
  let scene = SceneList.getScene(Parameters.scene);

  if (!scene) {
    loading.innerHTML = "<p>The scene wasn't found... :(</p>";
    return;
  }

  createRenderer();
  appendRendererTo(backgroundElem);

  setScene(scene);

  createScene()
      .then(() => {
        loading.remove();
        startRendering();
      })
      .catch(error => {
        console.error(error);
        loading.innerHTML = `<p>The scene caused an error. :(<br><br>${error}</p>`;
      });
}

// Vue 3: Verwende createApp statt new Vue
const app = createApp(App);
app.mount('#app');